import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import React from 'react';
import { Icon, IconType } from '../elements/Icon';
import Image from '../elements/Image';
import { Author } from '../flexible/Articles';

const ResourcesHero = ({ hero }) => {
  return (
    <div className="p-2 mx-auto md:px-5 md:py-4">
      <div className="w-full h-full text-white rounded-md bg-dark-purple">
        <div className="container pb-[70px] md:pb-[98px]">
          <div className=" relative flex items-center pt-32 md:pt-[10rem]">
            <div className="relative w-full ">
              <Icon type={IconType.Star} />
              <div className="mt-[14px]">
                <h1 className="text-h1">{hero?.heading}</h1>
                <div
                  className="mt-[21px] p-large opacity-60 max-w-[739px]"
                  dangerouslySetInnerHTML={{ __html: hero?.content }}
                />
              </div>
              <div className="mt-[30px] md:mt-[71px] w-full bg-white h-[1px] bg-opacity-50 hidden md:block "></div>
            </div>
          </div>
          <FeaturedPost {...hero?.featuredPost} />

          <div className="mt-[40px] md:mt-[168px]">
            <div className="flex flex-wrap ml-[-25px] gap-y-[40px]">
              {hero?.secondaryFeaturedPosts?.map((post, i) => (
                <div key={`post${i}`} className="pl-[25px] w-full md:w-1/2 ">
                  <SecondaryFeaturedPost {...post} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourcesHero;

export const FeaturedPost = ({ featuredImage, title, date, uri, author }) => (
  <Link to={uri}>
    <motion.div className="mt-[30px] md:mt-[79px]" initial="rest" whileHover="hover" animate="rest">
      <div className="relative justify-end w-full md:flex">
        <motion.div
          className="w-full max-w-[1098px]"
          variants={{
            rest: { scale: 1, transition: { duration: 0.4, ease: 'easeInOut' } },
            hover: {
              scale: 0.95,
              transition: { duration: 0.4, ease: 'easeInOut' },
            },
          }}
        >
          <Image image={featuredImage?.node} className="w-full h-full max-w-[1098px]" />
        </motion.div>

        <div className="md:absolute md:bottom-[-69px] left-0">
          <motion.div
            style={{ transformOrigin: 'bottom left' }}
            className="bg-blue  max-w-[482px] text-[18px] md:text-[28px] leading-[1.18] tracking-[-0.01em] font-bold "
            variants={{
              rest: { scale: 1, transition: { duration: 0.4 } },
              hover: {
                scale: 1.1,
                transition: { duration: 0.4 },
              },
            }}
          >
            <div className="md:py-[69px] py-[30px] px-6 md:px-[64px]">
              <div>
                {title}
                <div className="mt-[10px] text-[16px] font-normal">
                  <Author className="flex-wrap md:flex-nowrap opacity-60" author={author?.node?.name} date={date} />
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  </Link>
);

export const SecondaryFeaturedPost = ({ featuredImage, date, title, uri, author }) => {
  return (
    <Link to={uri} className=" group">
      <div className="relative flex-none overflow-hidden rounded aspect-w-588 aspect-h-365 image-absolute ">
        {featuredImage ? (
          <Image
            objectFit="cover"
            image={featuredImage?.node}
            className="max-w-[587px] absolute overflow-hidden group-hover:scale-[1.05] transition-transform duration-700"
          />
        ) : (
          <div className="flex items-center justify-center w-full h-full rounded bg-blue overflow-hidden group-hover:scale-[1.05] transition-transform duration-700">
            <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 26.5C0 26.9098 0.268683 27.0464 0.537365 27.0464C14.3772 29.7784 23.1113 37.5644 25.5298 52.4537C25.5298 52.7269 25.7985 53 26.0672 53C26.3359 53 26.4702 52.7269 26.4702 52.4537C28.889 37.5644 37.6228 29.7784 51.4626 27.0464C51.7313 27.0464 52 26.7732 52 26.5C52 26.2268 51.7313 25.9536 51.4626 25.9536C37.6228 23.2217 28.889 15.4356 26.4702 0.546391C26.4702 0.273197 26.2015 0 25.9328 0C25.6642 0 25.5298 0.273197 25.5298 0.546391C23.1113 15.4356 14.3772 23.2217 0.537365 25.9536C0.268683 25.9536 0 26.0902 0 26.5Z"
                fill="white"
              />
            </svg>
          </div>
        )}
      </div>
      <div className="mt-[30px] md:mt-[52px] group-hover:opacity-70 duration-300 transition-opacity">
        <h3 className="text-[18px] md:text-[28px] leading-[1.18] tracking-[-0.01em] font-bold max-w-[538px] font-body">
          {title}
        </h3>
        <div className="mt-[13px]">
          <Author author={author?.node?.name} date={date} />
        </div>
      </div>
    </Link>
  );
};
