import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const url = '//ablestar.us6.list-manage.com/subscribe/post-json?u=a1ce1c54b455c1abb393d1c06&id=30c74de82b';

const Newsletter = ({ heading, content }) => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<{ status?: string; message?: string }>({});

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (email) {
      setStatus({ status: 'fetching' });
      fetch('https://email-subscribe.ablestar.com', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          setStatus(res);
          if(res?.status === 'ok' && window && window.dataLayer){
            window.dataLayer.push({ event: 'newsletter_subscribe'});
          }
        })
        .catch(() => {
          setStatus({ status: 'error' });
        });
    }
  };
  return (
    <section className="pb-[114px]">
      <div className="container">
        <div className="default-blue-gradient rounded-[10px] ">
          <div className="flex flex-wrap items-center  py-[72px] px-6 md:px-[83px] noisy">
            <div className="w-full lg:w-7/12">
              <div className="lg:pr-3 xl:pr-0">
                <h2 className="text-h2">{heading}</h2>
                <div className="mt-[18px] p-large">{content}</div>
                <div className="mt-[43px] blog-newsletter">
                  {/* <MailchimpSubscribe
                    url={url}
                    onSubmitted={formData => {
                      subscribe(formData)
                      if(window && window.dataLayer){
                        window.dataLayer.push({ event: 'newsletter_form_submitted'})
                      }
                    }}
                  /> */}
                  <div>
                    {status?.status === 'fetching' ? (
                      <div style={{ color: 'blue' }}>Subscribing...</div>
                    ) : status?.status === 'ok' ? (
                      <div style={{ color: 'green' }}>Thank you for subscribing!</div>
                    ) : status?.status === 'error' ? (
                      <div style={{ color: 'red' }}>
                        {status.message ?? 'There was an error adding your email address. Please try again'}
                      </div>
                    ) : (
                      <></>
                    )}
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => handleChange(e)}
                      onKeyDown={(e) => handleKeyDown(e)}
                      placeholder="Your email"
                    />
                    <button onClick={() => handleSubmit()}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full mt-12 lg:w-5/12 lg:mt-0">
              <StaticImage src={`../../assets/images/newsletter.png`} alt="newsletter" className={`lg:mb-[-52px]`} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
